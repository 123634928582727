/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-unresolved */
import { readdirSync } from 'fs';

import { GetStaticPaths, GetStaticProps } from 'next';
import Head from 'next/head';
import React, { ReactElement, ReactNode } from 'react';
import { Fragment } from 'react';

import { Breadcrumbs } from '@components/Breadcrumbs';
import { ExitPreview } from '@components/Button';
import { Container } from '@components/Container';
import { HomePageCarousel } from '@components/HomePageCarousel/HomePageCarousel';
import { HorizontalDivider } from '@components/HorizontalDivider/HorizontalDivider';
import { StandardLayout } from '@components/Layouts/StandardLayout';
import { PortableTextBlockRenderer } from '@components/PortableText/PortableText';
import { LabResultList } from '@components/Product';
import { SocialShares } from '@components/Social';
import { HomePageCarouselItem } from '@interfaces/ICarousel';
import { InformationPageContent } from '@interfaces/InformationPage';
import { InformationPage } from '@interfaces/InformationPage';
import { PopularSearch } from '@interfaces/IPopularSearch';
import { MetaData } from '@interfaces/MetaData';
import { S3LabResult } from '@interfaces/Product';
import { FAQ } from '@interfaces/Sanity';
import {
  getAllInformationPageSlugs,
  getHastaLabResults,
  getInformationPageDataById,
  getInformationPageDataUsingCache,
  getMetaData,
} from '@lib/data/informationPageData';
import { addBigCommerceDataToBaseProducts } from '@lib/data/productData';
import { getRedirect } from '@lib/redirects';
import { convertFAQToStructedData } from '@lib/structuredData';
import { log, logBreadcrumb } from '@lib/utils';
interface LabResultGroup {
  dateGroup?: string;
  productGroup?: string;
  items: S3LabResult[];
}

const RendererOverride = {
  block: {
    h2: ({ children }: { children?: ReactNode }) => (
      <>
        <HorizontalDivider />
        <h2 className="italic has-[~figure]:inline-block has-[~figure]:w-fit">
          {children}
        </h2>
      </>
    ),
    h3: ({ children }: { children?: ReactNode }) => (
      <>
        <HorizontalDivider className="via-grey-mid" />
        <h3 className="font-bold">{children}</h3>
      </>
    ),
  },
};

export interface PageProps {
  pageData: InformationPage;
  breadcrumbs: { title: string; path: string }[];
  labResults: LabResultGroup[];
  meta: MetaData;
  faqStructuredData: unknown;
  isPreview: boolean;
  homeCarouselItems?: HomePageCarouselItem[];
  popularSearches: { slug: string; label: string }[];
}

// eslint-disable-next-line no-restricted-syntax
export default function InfoPage({
  pageData,
  breadcrumbs,
  labResults,
  meta,
  faqStructuredData,
  isPreview,
  homeCarouselItems,
}: PageProps): ReactElement {
  /**
   * Render the standard content block of the page
   * @param contentBlock as an object {_key: string; contentDescription: string; contentBody: (PortableText | FAQ)[]}
   * @returns ReactElement
   */
  const getPortableTextBlock = (
    contentBlock: InformationPageContent
  ): ReactElement => (
    <PortableTextBlockRenderer
      content={contentBlock.contentBody}
      componentsOverride={RendererOverride}
    />
  );
  /**
   * Render lab results (Lab Tests Block and Test Results Block) of the page
   * @param contentBlock
   * @param labResult as an object {dateGroup: string; productGroup: string; items: S3LabResult[]}
   * @returns ReactElement
   */
  const getLabResultBlock = (
    contentBlock: InformationPageContent,
    labResult: LabResultGroup
  ): ReactElement => {
    const title =
      contentBlock.contentDescription === 'Test Results'
        ? labResult.productGroup
        : labResult.dateGroup;
    const id = labResult.items[0].name.replace(/\s/g, '-').toLowerCase();
    return (
      <div id={id} key={title}>
        <HorizontalDivider />
        <h3>{title}</h3>
        <LabResultList items={labResult.items} />
      </div>
    );
  };

  /**
   * Render all the content blocks (including special cases for Lab Tests Block and Test Results Block) of the page
   * @param contentBlock
   * @returns ReactElement
   */
  const getArticleBlock = (
    contentBlock: InformationPageContent
  ): ReactElement => {
    // Lab Tests block is used on Hasta-Certification FAQ page
    // Test Results block is used on Protein-Testing FAQ page
    if (
      contentBlock.contentDescription === 'Lab Tests' ||
      contentBlock.contentDescription === 'Test Results'
    ) {
      return (
        <>
          {getPortableTextBlock(contentBlock)}
          {labResults.map((result) => getLabResultBlock(contentBlock, result))}
        </>
      );
    }

    return getPortableTextBlock(contentBlock);
  };

  return (
    <>
      <Head>
        <>
          <title>{meta.metaTitle}</title>
          {faqStructuredData && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(faqStructuredData),
              }}
            />
          )}
          <meta name="title" content={meta.metaTitle} />
          <meta name="description" content={meta.metaDescription} />
          {meta.noIndex && <meta name="robots" content="noindex" />}
          <link
            rel="canonical"
            href={
              meta.canonical
                ? meta.canonical
                : `${process.env.NEXT_PUBLIC_SERVER_URL}${pageData.slug}`
            }
          />
        </>
      </Head>
      {isPreview && <ExitPreview />}
      {pageData.breadcrumbs && (
        <Container>
          <Breadcrumbs crumbs={breadcrumbs} />
        </Container>
      )}

      <main id="main-content">
        {pageData.title === 'Homepage' && homeCarouselItems && (
          <HomePageCarousel carouselItems={homeCarouselItems} />
        )}
        <Container
          as="article"
          data-narrow={pageData.narrow}
          className="data-[narrow=true]:grid-cols-[1fr_min(theme(width.narrowPage),_calc(100%_-_16px))_1fr]"
        >
          {pageData.content.map((contentBlock, i) => (
            <Fragment key={i}>{getArticleBlock(contentBlock)}</Fragment>
          ))}
          {/* Default to showing to the social shares unless specified */}
          {pageData.hideSocialShares !== true && (
            <SocialShares
              type={'page'}
              shareSlug={pageData.slug}
              titleText={pageData.title}
            />
          )}
        </Container>
      </main>
    </>
  );
}

InfoPage.getLayout = function getLayout(
  page: ReactElement & { props: PageProps }
) {
  return (
    <StandardLayout
      includeHeader={page.props.pageData.header}
      includeFooter={page.props.pageData.footer}
    >
      {page}
    </StandardLayout>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  const paths = await getAllInformationPageSlugs();
  // remove any we have snowflakes for
  const snowflakes = readdirSync('pages');

  return {
    paths: paths.filter(
      (path) => !snowflakes.includes(path.params.page.join('/') + '.tsx')
    ),
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async ({
  params,
  preview,
  previewData,
}) => {
  try {
    if (preview) {
      // This is preview mode
      const pageData = await getInformationPageDataById(
        (previewData as { id: string }).id
      );

      if (!pageData) {
        // try redirect
        const redirect = await getRedirect(
          ('/' + params?.page?.toString().split(',').join('/')) as string
        );
        if (redirect) {
          return {
            redirect: {
              destination: redirect.newUrl,
              permanent: redirect.permanent,
            },
          };
        }

        // fallback failed
        return {
          notFound: true,
        };
      }

      const breadcrumbs = [
        { title: 'home', path: '/' },
        { title: pageData.title, path: pageData.slug },
      ];

      const meta = await getMetaData(pageData._id);

      let labResults: LabResultGroup[] = [];
      // if page is hast-certification pull lab tests for hasta
      if (pageData.slug === '/faqs/hasta-certification') {
        labResults = await getHastaLabResults();
      }

      const faqs = pageData.content
        ? (
            pageData.content
              .map((d) => d.contentBody)
              .flat()
              .filter((c) => c && c._type === 'faq') as FAQ[]
          ).map((faq) => ({
            question: faq.question,
            answer: faq.answer
              .map((a) => a.children.map((c) => c.text).join(' '))
              .join(' '),
          }))
        : [];

      const faqStructuredData =
        faqs.length > 0
          ? {
              '@context': 'http://schema.org',
              '@type': 'FAQPage',
              mainEntity: faqs.map((faq) => ({
                '@type': 'Question',
                name: faq.question,
                acceptedAnswer: {
                  '@type': 'Answer',
                  text: faq.answer,
                },
              })),
            }
          : null;

      return {
        props: {
          pageData,
          breadcrumbs,
          labResults,
          meta,
          faqStructuredData,
          isPreview: true,
        },
      };
    }

    const pageData = await getInformationPageDataUsingCache(
      (params?.page as string[]).join('/')
    );

    if (!pageData) {
      // try redirect
      const redirect = await getRedirect(
        ('/' + params?.page?.toString().split(',').join('/')) as string
      );
      if (redirect) {
        return {
          redirect: {
            destination: redirect.newUrl,
            permanent: redirect.permanent,
          },
        };
      }

      // fallback failed
      return {
        notFound: true,
      };
    }

    const breadcrumbs = [
      { title: 'home', path: '/' },
      { title: pageData.title, path: pageData.slug },
    ];

    const meta = await getMetaData(pageData._id);

    let labResults: LabResultGroup[] = [];
    // if page is hast-certification pull lab tests for hasta
    if (pageData.slug === '/faqs/hasta-certification') {
      labResults = await getHastaLabResults();
    }

    const faqs = pageData.content
      ? (
          pageData.content
            .map((d) => d.contentBody)
            .flat()
            .filter((c) => c && c._type === 'faq') as FAQ[]
        ).map((faq) => ({
          question: faq.question,
          answer: faq.answer
            .map((a) => a.children.map((c) => c.text).join(' '))
            .join(' '),
        }))
      : [];

    const faqStructuredData = convertFAQToStructedData(faqs);

    // If there is a product carousel, add the BC data that is needed for a BaseProduct
    // Get all products out
    const products = [] as Array<any>;
    pageData.content.forEach((content) => {
      content.contentBody.forEach((block) => {
        if (block._type === 'carousel' && block.items[0]._type === 'product') {
          products.push(...block.items);
        }
      });
    });

    // Map products
    const mappedProducts = await addBigCommerceDataToBaseProducts(products);

    // Replace them
    pageData.content.forEach((content) => {
      content.contentBody = content.contentBody.map((block) => {
        if (block._type === 'carousel' && block.items[0]._type === 'product') {
          const mappedItems = block.items.map((item) =>
            mappedProducts.find((p) => p.entityId === item.entityId)
          );
          return {
            ...block,
            items: mappedItems,
          };
        }
        return block;
      });
    });

    return {
      props: {
        pageData,
        breadcrumbs,
        meta,
        labResults,
        faqStructuredData,
      },
    };
  } catch (error) {
    logBreadcrumb({
      category: 'pageRenderError',
      message: 'Error in getStaticProps for information page ' + params?.page,
      level: 'error',
    });
    log({
      error,
      location: `getStaticProps ${(params?.page as string[]).join('/')}`,
    });
    return {
      notFound: true,
    };
  }
};
